import React from 'react'

const VideoPromocional = ({ linkVideo, image, title, text }) => {
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image}")`
            }}
        >
            <div className='countentVideoInfo'>
                <div className='info'>
                    <h1 className='text-white'>{title}</h1>
                </div>
                 <iframe
                    title="Promotional video"
                    className='video_resourse'
                    id="ytplayer"
                    type="text/html"
                    src={`https://www.youtube.com/embed/${linkVideo}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo}`}
                    frameborder="0" allowfullscreen />
            </div>
        </div>
    )
}

export default VideoPromocional


